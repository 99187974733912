<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="New Risk Assessment" size="lg">
      <validation-observer ref="riskassessment" v-slot="{ invalid }">
        <b-form
          class="mt-2"
          @submit.prevent="handleNewRiskAssessmentModalSubmit"
        >
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="newRiskAssessment.title"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Note -->
          <b-col>
            <b-form-group label="Note">
              <template slot="label">
                Note <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="note"
                rules="required"
              >
                <b-form-textarea
                  id="note"
                  v-model="newRiskAssessment.notes"
                  rows="3"
                  max-rows="6"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              Confirm
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-header">
          <div
            class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
          >
            <div class="w-100 row justify-content-between align-items-center">
              <div
                class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
              >
                <h3 class="mb-50 font-weight-bold">Risk Assessment</h3>
                <p class="mb-0">Manage your risk assessments.</p>
              </div>
              <div class="col-md-6 col-sm-12">
                <div
                  class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
                >
                  <b-button
                    @click="handleNewRiskAssessmentClick"
                    size="sm"
                    variant="outline-success"
                    class="mr-1"
                    ><feather-icon class="mr-50" icon="PlusIcon" />New Risk
                    Assessment</b-button
                  >
                  <b-button
                    @click="()=>{$router.push('/risk-register')}"
                    size="sm"
                    variant="success"
                    >Go to Risk Register<feather-icon class="ml-50" icon="ArrowRightCircleIcon" /></b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Notes</th>
                <th role="columnheader" scope="col">Assessed User</th>
                <th role="columnheader" scope="col">Created At</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="assessments && assessments.length > 0">
                <tr
                  v-for="assessment in assessments"
                  :key="assessment._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleRowClick(assessment._id)"
                >
                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <div
                      style="width: 200px"
                      class="d-flex justify-content-start"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.title"
                      v-if="assessment.title && assessment.title !== null"
                    >
                      <p
                        class="text-left text-truncate font-weight-bolder mb-0"
                      >
                        {{ assessment.title }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <div
                      style="width: 200px"
                      class="d-flex justify-content-start"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.notes"
                      v-if="assessment.notes && assessment.notes !== null"
                    >
                      <p class="text-left text-truncate font-weight-bold mb-0">
                        {{ assessment.notes }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    <div class="d-flex justify-content-start">
                      <p
                        class="text-left font-weight-bold mb-0"
                        v-if="
                          assessment.assessed_user &&
                          assessment.assessed_user !== null
                        "
                      >
                        {{ assessment.assessed_user.firstname }}
                        {{ assessment.assessed_user.lastname }}
                      </p>
                      <b-badge v-else variant="danger">Deleted User</b-badge>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    <div
                      class="d-flex justify-content-start"
                      v-if="
                        assessment.created_at && assessment.created_at !== null
                      "
                    >
                      <p class="text-left font-weight-bold mb-0">
                        {{ assessment.created_at | moment }}
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Risk Assessment Not Found">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      It seems like there are no Risk Assessments at the moment.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>

          <div class="mt-3">
            <div class="row">
              <div
                class="d-flex align-items-center justify-content-center col-12"
              >
                <b-pagination
                  v-model="filters.page"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.rows"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import RiskAssessmentMixins from "@//mixins/RiskAssessmentMixins";
import ResponseMixins from "@//mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DonutBar from "@/components/DonutBar.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import moment from "moment";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BFormInput,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    EmptyTableSection,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
      filters: {
        page: 1,
      },
      newRiskAssessment: {
        title: null,
        notes: null,
      },
      assessments: null,
    };
  },
  mixins: [ResponseMixins, RiskAssessmentMixins, UtilsMixins],
  watch: {
    filters: {
      handler: function (newValue) {
        this.getRiskAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  methods: {
    load() {
      this.getRiskAssessmentsAndSetData(this.filters);
      this.handleQueryActions();
    },

    handleQueryActions() {
      const queryParams = this.$route.query;
      if ("openNewAssessmentModal" in queryParams) {
        this.handleNewRiskAssessmentClick();
      }
      this.$router.replace({ query: null });
    },

    handleRowClick(assessmentId) {
      this.$router.push(`/riskassessment/${assessmentId}/risks`);
    },

    getRiskAssessmentsAndSetData(params) {
      this.showOverlay = true;
      this.getRiskAssessments(params)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewRiskAssessmentModalSubmit() {
      this.storeRiskAssessment({
        title: this.newRiskAssessment.title,
        notes: this.newRiskAssessment.notes,
      })
        .then((res) => {
          this.load();
          this.handleResponse(res);
          // console.log("created", res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    handleNewRiskAssessmentClick() {
      this.newRiskAssessment.title = null;
      this.newRiskAssessment.notes = null;
      this.showModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
}
</style>